<template>
	<div>
		<s-crud
			title="PrePacking"
			:config="config"
			add
			edit
			@save="save($event)"
			:filter="filter"

		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<s-date
								label="Fecha"
								v-model="props.item.PpkDate"
							></s-date>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<sselect-client-sap
								label="Cliente"
								v-model="props.item.CustomerID"
							></sselect-client-sap>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<s-text
								label="N° Embarque"
								v-model="props.item.PpkNumberShipment"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<s-text
								label="Empaque"
								v-model="props.item.PpkPacking"
							></s-text>
						</v-col>
					</v-row>
					
					<v-row>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<s-text
								label="N° Versión"
								v-model="props.item.PpkNumberVersion"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="3" sm="3" class="s-col-form">
							<s-text
								label="Motivo Cambio"
								v-model="props.item.PpkReasonChange"
							></s-text>
						</v-col>
						<v-col cols="3" lg="6" md="6" sm="12" class="s-col-form">
							<s-text
								label="Motivo Cambio"
								v-model="props.item.PpkObservation"
							></s-text>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-data-table
								:headers="headersPalletsPre"
								:items="palletsPre"
								:search="search"
								@click:row="rowSelect"
							>
								<template v-slot:item.accion="{ item }">
									<v-btn 
										rounded 
										color="primary" 
										fab 
										x-small 
										@click="addPallet(item)"
									>
										<i class="fas fa-plus"></i>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" lg="3">
							<s-text
								label="N° Cajas"
								v-model="PpdQuantityBox"
								number
								ref="textquantitybox"
							></s-text>
						</v-col>
						<v-col cols="12" lg="3">
							<s-text
								v-model="PpdWeight"
								label="Peso"
								decimal
								ref="textweight"
							></s-text>
						</v-col>
						<v-col cols="12" lg="3">
							<s-text
								v-model="PpdLotCustomer"
								label="Lote Cliente"
								ref="textlotcustomer"
							></s-text>
						</v-col>
						<v-col cols="12" lg="3">
							<s-date
								v-model="PpdExpirationDate"
								label="Fecha Vencimiento"
								ref="textexpirationdate"
							></s-date>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-data-table
								item-key="EpdID"
								:headers="headersPallets"
								:items="pallets"
							>
								<template v-slot:item.accion="{ item }">
									<v-btn 
										rounded 
										color="red" 
										fab 
										x-small 
										@click="removePallet(item)"
									>
										<i class="fas fa-trash"></i>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sBoardginChamberService from "@/services/FrozenProduction/BoardingChamberService.js";
	import _sPrePackingService from "@/services/FrozenProduction/PrePackingService.js";
	import SselectClientSap from '../../../components/Sale/SselectClientSap.vue';

	export default {
  components: { SselectClientSap },
		data() {
			return {
				filter: {},
				config: {
					model: {

					},
					service: _sPrePackingService,
					headers: [
						{text: "ID", value: ""}
					]
				},
				headersPalletsPre: [
					{text: "Acción", value: "accion"},
					{text: "ID", value: "EpdID"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
					{text: "Presentación", value: "TypePresentationBoxName"},
					{text: "N° Cajas", value: "FbcQuantityBox"},
					{text: "Peso Neto(Kg)", value: "ExpWeightNet"},
				],
				headersPallets: [
					{text: "Acción", value: "accion"},
					{text: "ID", value: "EpdID"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
					{text: "Presentación", value: "TypePresentationBoxName"},
					{text: "N° Cajas", value: "PpdQuantityBox"},
					{text: "Peso", value: "PpdWeight"},
					{text: "Lote Cliente", value: "PpdLotCustomer"},
					{text: "Fecha vencimiento", value: "PpdExpirationDate"},
				],
				palletsPre: [],
				pallets: [],
				PpdQuantityBox: 0,
				PpdWeight: 0,
				PpdLotCustomer: "",
				PpdExpirationDate: "",
				search: "",
				palletPreSelected: {}
			}
		},

		methods: {
			Initialize(){
				_sBoardginChamberService
				.listpalletoutput(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.palletsPre = resp.data;
						console.log('initialize ', this.palletsPre);
					}
				})
			},

			addPallet(item)
			{
				if(this.PpdQuantityBox == 0){
					this.$fun.alert("Ingrese el número de cajas", "warning");
					this.$refs.textquantitybox.focus();
					return;
				}
				if(this.PpdQuantityBox > this.palletPreSelected.FbcQuantityBox){
					this.$fun.alert("El número de cajas sobrepasa al real", "warning");
					return;
				}
				if(this.PpdWeight == 0){
					this.$fun.alert("Ingrese el peso", "warning");
					this.$refs.textweight.focus();
					return;
				}
				if(this.PpdLotCustomer == ""){
					this.$fun.alert("Ingrese lote de cliente", "warning");
					this.$refs.textlotcustomer.focus();
					return;
				}

				item.PpdQuantityBox = this.PpdQuantityBox;
				item.PpdQuantityBoxOld = item.FbcQuantityBox - this.PpdQuantityBox;
				item.PpdWeight = this.PpdWeight;
				item.PpdLotCustomer = this.PpdLotCustomer;
				item.PpdExpirationDate = this.PpdExpirationDate;
				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();
				item.FbcQuantityBox = item.FbcQuantityBox - this.PpdQuantityBox;

				_sBoardginChamberService
				.save(item, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Agregado correctamente", "success");
						this.pallets.push(item);
						this.Initialize();
					}
				})

			},

			removePallet(item)
			{
				item.FbcQuantityBox = parseInt(item.PpdQuantityBoxOld) + parseInt(item.PpdQuantityBox);
				console.log('update ', item);
				_sBoardginChamberService
				.save(item, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Actualizado correctamente", "success");
						this.pallets = this.pallets.filter(e => {
							return e.FbcID != item.FbcID;
						});
						this.Initialize();
						
					}
				});
			},

			rowSelect(item, row)
			{
				
				console.log('itemm ', item);
			},

			save() {
				
			}
		},

		created () {
			this.Initialize();
		},
	}
</script>